import * as React from "react"
import {styled} from '@material-ui/core/styles';
import {graphql} from "gatsby"
import LayoutTmpl from "../components/layout";
import Container from "@material-ui/core/Container";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import {GatsbyImage} from "gatsby-plugin-image";
import Rating from "@material-ui/lab/Rating";
import {Helmet} from "react-helmet";
import Links from '@material-ui/core/Link';
import ReviewsSort from "../components/reviewsSort";
import CommentsSort from "../components/commentsSort";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import LiveHelpOutlinedIcon from "@material-ui/icons/LiveHelpOutlined";
import {useState} from "react";
import axios from "axios";
import DialogTitle from "@material-ui/core/DialogTitle";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
// import AdSense from "react-adsense";

const StyledPaper = styled(Paper)(({theme}) => ({
    padding: theme.spacing(2),
    margin: 'auto',
}));
const StyledContainer = styled(Container)(({theme}) => ({
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(8),
}));
const StyledRatingDiv = styled('div')(({theme}) => ({
    padding: 0,
    margin: 0,
}));
const StyledButtonBase = styled(ButtonBase)(({theme}) => ({
    width: 256,
}));

const ReviewPage = ({data}) => {

    const row = data.pageReviews
    const images = data.pageImages

    const [initRepresentative, setInitRepresentative] = useState(false);
    const [representativeAdd, setRepresentativeAdd] = useState([]);

    function openFormRepresentative() {
        setInitRepresentative(true);
    }

    function closeFormRepresentative() {
        setInitRepresentative(false);
        setRepresentativeAdd([]);
    }

    function handleRepresentativeChange(e) {
        const target = e !== undefined ? e.target : undefined;
        if (target !== undefined) {
            const value = target.type === 'checkbox' ? target.checked : target.value;
            const name = target.name;
            setRepresentativeAdd({
                ...representativeAdd, [name]: value
            });

        }
    }

    function addUserRepresentative() {
        let formData = new FormData();
        for (const [key, value] of Object.entries(representativeAdd)) {
            formData.append(key, value)
        }
        formData.append(
            "elementId", row.element.id,
        );
        formData.append(
            "jwt", JSON.parse(localStorage.getItem("gatsbyUser")).jwt,
        );
        formData.append(
            "email", JSON.parse(localStorage.getItem("gatsbyUser")).email,
        );
        axios({
            method: "POST",
            data: Object.assign(formData),
            url: "https://api.ocenivay.com/api/representative-add.php",
            responseType: 'json',
        })
            .then(res => {
                if (res.data.type === undefined) {
                    closeFormRepresentative();
                }
                setSnackbarMessage(res.data.severity, res.data.message)
            })
            .catch(function (error) {
                console.log('Error', error.message);
            });
    }

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState("success");
    const [message, setMessage] = useState("");

    function setSnackbarMessage(severity, message) {
        severity = severity ? severity : "warning";
        message = message ? message : "Что-то пошло не так... Попробуйте позже...";
        setSeverity(severity)
        setMessage(message)
        handleClick();
    }

    const handleClick = () => {
        setOpen(true)
    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false)
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={open} autoHideDuration={4000} onClose={handleClose}
            >
                <Alert variant="filled" onClose={handleClose} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>

            <Helmet defer={false}>
                <html lang="ru-RU"/>
                <title>Отзыв
                    на {row.element.name + " от " + row.element.reviews[0].user.name + ", " + row.element.reviews[0].date}</title>
                <meta name="description" content={row.element.reviews[0].text_preview}/>
                <meta name="keywords" content={row.element.name + " отзыв " + row.element.reviews[0].user.name}/>
                <link rel="canonical" href={"https://ocenivay.com" + row.element.reviews[0].url}/>
                <meta name="robots" content="index, follow"/>
                <meta name="x-robots-tag" content="all"/>
                <meta property="og:type" content="article"/>
                <meta property="og:site_name" content="Ocenivay.com"/>
                <meta property="og:title"
                      content={"Отзыв на " + row.element.name + " от " + row.element.reviews[0].user.name + ", " + row.element.reviews[0].date}/>
                <meta property="og:description" content={row.element.reviews[0].text_preview}/>
                <meta property="og:url" content={"https://ocenivay.com" + row.element.reviews[0].url}/>
                <meta property="og:locale" content="ru_RU"/>
                <meta property="og:image" content={images.images.element.original[0].src}/>
                <meta http-equiv="cache-control" content="no-cache"/>
                <meta http-equiv="expires" content="0"/>
                <meta http-equiv="pragma" content="no-cache"/>
            </Helmet>


            <Dialog open={initRepresentative}
                    onClose={closeFormRepresentative}
                    aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title"><VerifiedUserIcon style={{
                    margin: "0px 10px -5px 0px",
                    color: "green"
                }}/>Запрос на представителя</DialogTitle>
                <DialogContent>
                    <DialogContentText style={{
                        fontSize: "0.8rem"
                    }}>
                                                    <span
                                                        style={{
                                                            color: "#333333",
                                                        }}
                                                    >
                                                        Вы сможете официально отвечать на отзывы посетителй сайта.
                                                        Для верификации аккаунта, укажите официальный сайт и контактный
                                                        E-mail (должен быть размещен на указанном сайте) на который
                                                        будет
                                                        выслан код подтверждения.
                                                    </span><br/>
                        <Links
                            href="/feedback"
                            style={{
                                color: '#3f51b5',
                                textShadow: 'none',
                                backgroundImage: 'none',
                                fontSize: '0.7rem',
                            }}
                        >Остались вопросы?</Links>
                    </DialogContentText>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                required
                                margin="dense"
                                id="site"
                                defaultValue="https://"
                                name="site"
                                label="Сайт"
                                type="text"
                                fullWidth
                                onChange={handleRepresentativeChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                required
                                margin="dense"
                                id="email"
                                name="email"
                                label="E-mail"
                                type="email"
                                fullWidth
                                onChange={handleRepresentativeChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions
                    style={{
                        margin: "20px 16px 10px 16px",
                    }}
                ><Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="baseline"
                >
                    <Grid>
                        <Button onClick={closeFormRepresentative}
                                variant="contained" size="small"
                                style={{
                                    textShadow: 'none',
                                    backgroundImage: 'none',
                                    fontSize: '0.8rem',
                                    fontWeight: 400,
                                    textTransform: 'none',
                                    margin: "0 20px 0 0",
                                }}
                        >
                            Отменить
                        </Button>
                    </Grid>
                    <Grid>
                        <Button onClick={addUserRepresentative}
                                variant="contained" size="small" color="primary"
                                style={{
                                    textShadow: 'none',
                                    backgroundImage: 'none',
                                    fontSize: '0.8rem',
                                    fontWeight: 400,
                                    textTransform: 'none',
                                }}
                        >
                            Отправить запрос
                        </Button>
                    </Grid>
                </Grid>
                </DialogActions>
            </Dialog>


            <div>
                <div itemType="http://schema.org/Product" itemScope>
                    <meta itemProp="mpn" content={row.element.id}/>
                    <meta itemProp="name"
                          content={row.element.name + " - отзыв от " + row.element.reviews[0].user.name}/>
                    <link itemProp="image" href={images.images.element.original[0].src}/>
                    <meta itemProp="description" content={row.element.reviews[0].text_preview}/>
                    <div itemProp="aggregateRating" itemType="http://schema.org/AggregateRating" itemScope>
                        <meta itemProp="reviewCount" content={row.element.reviews_count_number}/>
                        <meta itemProp="ratingValue" content={parseFloat(row.element.rating)}/>
                    </div>
                    <div itemProp="review" itemType="http://schema.org/Review" itemScope>
                        <div itemProp="author" itemType="http://schema.org/Person" itemScope>
                            <meta itemProp="name" content={row.element.reviews[0].user.name}/>
                        </div>
                        <div itemProp="reviewRating" itemType="http://schema.org/Rating" itemScope>
                            <meta itemProp="ratingValue" content={parseFloat(row.element.reviews[0].rating.number)}/>
                            <meta itemProp="bestRating" content="5"/>
                        </div>
                    </div>
                </div>
            </div>

            <LayoutTmpl>

                <main>

                    <StyledContainer maxWidth="md">

                        <StyledPaper>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm container>
                                    <Grid item xs container direction="column">
                                        <Grid item xs>
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="flex-start"
                                            >
                                                <Grid>
                                                    <StyledRatingDiv>
                                                        <Rating name="half-rating-read"
                                                                style={{
                                                                    margin: '0 10px 0 0'
                                                                }}
                                                                value={parseFloat(row.element.rating)}
                                                                precision={0.5} readOnly/>
                                                    </StyledRatingDiv>
                                                </Grid>
                                                <Grid
                                                    style={{
                                                        padding: '3px 0 0 0'
                                                    }}
                                                >
                                                    <Links href={row.element.url} style={{
                                                        textDecoration: 'underline',
                                                        fontSize: '0.8rem',
                                                    }}
                                                    >Рейтинг {
                                                        ((parseFloat(row.element.rating) < 3) &&
                                                            <Chip
                                                                size="small"
                                                                label={parseFloat(row.element.rating)}
                                                                style={{
                                                                    width: "2.7rem",
                                                                    height: "1rem",
                                                                    fontSize: '0.8rem',
                                                                    padding: '1px 0 0 0',
                                                                    margin: '-3px 0 0 0',
                                                                    backgroundColor: 'red',
                                                                    color: 'white',
                                                                }}/>
                                                        )
                                                    }
                                                        {
                                                            ((parseFloat(row.element.rating) >= 3 && parseFloat(row.element.rating) < 4) &&
                                                                <Chip
                                                                    size="small"
                                                                    label={parseFloat(row.element.rating)}
                                                                    style={{
                                                                        width: "2.7rem",
                                                                        height: "1rem",
                                                                        fontSize: '0.8rem',
                                                                        padding: '1px 0 0 0',
                                                                        margin: '-3px 0 0 0',
                                                                        backgroundColor: '#F3DD10FF',
                                                                        color: 'black',
                                                                    }}/>
                                                            )
                                                        }
                                                        {
                                                            ((parseFloat(row.element.rating) >= 4) &&
                                                                <Chip
                                                                    size="small"
                                                                    label={parseFloat(row.element.rating)}
                                                                    style={{
                                                                        width: "2.7rem",
                                                                        height: "1rem",
                                                                        fontSize: '0.8rem',
                                                                        padding: '1px 0 0 0',
                                                                        margin: '-3px 0 0 0',
                                                                        backgroundColor: 'green',
                                                                        color: 'white',
                                                                    }}/>
                                                            )
                                                        } на
                                                        основе {row.element.reviews_count_number} {row.element.reviews_count_text}</Links>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs>
                                            <Links href={row.element.url}
                                                   style={{
                                                       color: '#000000',
                                                       textShadow: 'none',
                                                       backgroundImage: 'none',
                                                       fontSize: '1.2rem',
                                                       fontWeight: 400,
                                                   }}
                                                   dangerouslySetInnerHTML={{__html: row.element.name}}
                                            />
                                            <Typography variant="body2" gutterBottom
                                                        dangerouslySetInnerHTML={{__html: row.element.text}}/>
                                            <Links href={row.element.url}
                                                   style={{
                                                       textDecoration: 'underline',
                                                   }}
                                            >Подробнее...</Links>
                                        </Grid>
                                        <Grid item xs><Typography variant="body2" component="p"
                                                                  color="textPrimary"
                                                                  style={{
                                                                      fontWeight: 'normal',
                                                                      fontSize: '0.7rem',
                                                                      margin: '10px 0px 20px 0px'
                                                                  }}

                                        >
                                            {
                                                (row.element.tags !== undefined ?
                                                        row.element.tags.map((tag, t) => {
                                                                return (
                                                                    <span key={t}>
                                                                        <Links href={tag.url}
                                                                               style={{
                                                                                   color: '#3f51b5',
                                                                                   textShadow: 'none',
                                                                                   backgroundImage: 'none',
                                                                                   fontSize: '0.7rem',
                                                                                   margin: '0px 10px 0px 0px'
                                                                               }}
                                                                        >#{tag.name}</Links>&nbsp;
                                                                    </span>
                                                                )
                                                            }
                                                        )
                                                        :
                                                        ""
                                                )
                                            }</Typography>
                                        </Grid>
                                        <Grid item xs>
                                            {
                                                (
                                                    !row.element.representative ?
                                                        <Links
                                                            onClick={openFormRepresentative}
                                                            style={{
                                                                textShadow: 'none',
                                                                backgroundImage: 'none',
                                                                fontSize: '0.8rem',
                                                                fontWeight: "400",
                                                                textTransform: 'none',
                                                                margin: '0 0 -10px 0',
                                                                cursor: "pointer",
                                                                color: "#969696"
                                                            }}
                                                        >Вы представитель<LiveHelpOutlinedIcon style={{
                                                            margin: '0 0 -5px 1px',
                                                            fontSize: '1.2rem',
                                                        }}/></Links>
                                                        :
                                                        <Typography variant="body2" component="p"
                                                                    color="textPrimary"
                                                                    style={{
                                                                        fontWeight: 'normal',
                                                                        fontSize: '0.7rem',
                                                                        color: "#4caf50"
                                                                    }}

                                                        ><VerifiedUserIcon style={{
                                                            fontWeight: 'normal',
                                                            fontSize: '0.7rem',
                                                            margin: "0px 0px -1px 0px"
                                                        }}/> Представитель на сайте</Typography>
                                                )
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <StyledButtonBase>
                                        {
                                            (images.localImages[0] !== 'undefined' && images.localImages[0] !== null
                                                    ?
                                                    <Links href={row.element.url}><GatsbyImage
                                                        image={images.localImages[0].childImageSharp.gatsbyImageData}
                                                        alt={row.element.name + " - отзыв"}/></Links>
                                                    : ""
                                            )
                                        }
                                    </StyledButtonBase>
                                </Grid>
                            </Grid>
                        </StyledPaper>

                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            style={{
                                margin: '25px 0 0px 0',
                            }}
                        >
                            <Grid>
                                <Button variant="contained"
                                        style={{
                                            textShadow: 'none',
                                            backgroundImage: 'none',
                                            fontSize: '0.8rem',
                                            fontWeight: "bold",
                                            textTransform: 'none',
                                            margin: '5px',
                                            backgroundColor: "#fc0",
                                            color: "black",
                                        }}
                                        color="secondary"
                                        component={Links}
                                        href={"/app/review?elementId=" + row.element.id}
                                >Оставить отзыв</Button>
                            </Grid>
                        </Grid>

                        <ReviewsSort reviews={row.element.reviews} element={row.element}
                                     reviewId={row.element.reviews[0].id} type={1}/>
                        <CommentsSort reviewId={row.element.reviews[0].id}/>

                    </StyledContainer>

                </main>

            </LayoutTmpl>
        </>
    )
}

export default ReviewPage

export const pageQuery = graphql`
  query reviewByUrl($url: String!, $element: String!) {
    site {
      siteMetadata {
        title
      }
    }
    pageReviews(element: {reviews: {elemMatch: {url: {eq: $url}}}}) {
        id
        element {
          id
          name
          rating
          reviews {
            id
            getlastmod
            elementId
            representative
            date
            rating {
              number
              text
            }
            text
            text_preview
            text_plus
            text_minus
            text_comment
            vote {
              plus
              minus
            }
            comments
            spam
            user {
              name
              avatar
              id
            }
            url
          }
          tags {
            url
            name
          }
          url
          text_short
          text
          reviews_count_text
          reviews_count_number
          representative
        }
    }
    pageImages(element: {eq: $element}) {
        localImages {
            childImageSharp {
                gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                    width: 537
                    height: 382
                )
            }
        }
        images {
            element {
                original {
                    width
                    src
                    height
                }
                preview {
                    src
                    size {
                        width
                        height
                    }
                }
                thumbnail {
                    width
                    src
                    height
                }
            }
        }
    }  
}
`
