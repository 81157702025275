import React, {useEffect, useState, useRef} from 'react'
import PropTypes from "prop-types";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import {styled} from "@mui/material/styles";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import Link from "@material-ui/core/Link";
import CommentIcon from "@material-ui/icons/Comment";
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';
import ReplyIcon from '@material-ui/icons/Reply';
import Button from '@material-ui/core/Button';
import Snackbar from "@material-ui/core/Snackbar";
import {Alert} from "@material-ui/lab";
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

const StyledPaper = styled(Paper)(({theme}) => ({
    padding: theme.spacing(2),
    margin: 'auto',
}));

function createTree(list) {
    var map = {},
        node,
        roots = [],
        i

    for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i // initialize the map
        list[i].children = [] // initialize the children
    }

    for (i = 0; i < list.length; i += 1) {
        node = list[i]
        if (node.parentId) {
            // if you have dangling branches check that map[node.parentId] exists
            list[map[node.parentId]].children.push(node)
        } else {
            roots.push(node)
        }
    }
    return roots
}

function Comment({
                     comment,
                     addReviewComment,
                     showReviewAddComment,
                     showReviewEditComment,
                     handleCommentChange,
                     commentShowAdd,
                     commentShowEdit,
                     formDeleteComment,
                     deleteComment,
                     closeReviewComment
                 }) {
    const nestedComments = (comment.children || []).map((comment) => {
        return <Comment
            key={comment.id} comment={comment} type="child"
            addReviewComment={addReviewComment}
            showReviewAddComment={showReviewAddComment}
            showReviewEditComment={showReviewEditComment}
            handleCommentChange={handleCommentChange}
            commentShowAdd={commentShowAdd}
            commentShowEdit={commentShowEdit}
            formDeleteComment={formDeleteComment}
            deleteComment={deleteComment}
            closeReviewComment={closeReviewComment}
        />
    })

    return (
        <div style={{marginLeft: '25px', marginTop: '16px', marginRight: '25px'}}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {commentShowEdit.show === comment.id && <Grid item xs={12}>
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Paper style={{
                                padding: '2px 4px',
                                display: 'flex',
                                alignItems: 'center',
                                width: "100%",
                                textAlign: "center",
                                margin: "0px 0px 25px 0px"
                            }}>
                                <InputBase
                                    style={{
                                        marginLeft: "10px",
                                        flex: 1,
                                    }}
                                    multiline
                                    minRows={1}
                                    maxRows={8}
                                    placeholder="Ваш комментарий"
                                    defaultValue={comment.textEdit}
                                    onChange={event => handleCommentChange(event, comment.id)}
                                />
                                <Divider style={{
                                    height: 28,
                                    margin: 4,
                                }} orientation="vertical"/>
                                <IconButton
                                    onClick={() => Promise.resolve(addReviewComment(comment.id, comment.id))}
                                    color="primary"
                                    style={{
                                        padding: 10,
                                    }} aria-label="directions">
                                    <SendIcon/>
                                </IconButton>
                            </Paper>
                        </Grid>
                    </Grid>}
                    {commentShowEdit.show !== comment.id && <Typography variant="body2" gutterBottom
                                                                        dangerouslySetInnerHTML={{__html: comment.textView}}/>}
                </Grid>
                <Grid item>
                    <Grid container spacing={2}>
                        <Grid item>
                            {
                                (comment.user.id > 0 ?
                                        (comment.user.avatar ?
                                            <Avatar alt={comment.user.name}
                                                    src={comment.user.avatar}
                                                    style={{
                                                        height: "25px",
                                                        width: "25px",
                                                        margin: "-5px -10px 0px 0px"
                                                    }}
                                            /> :
                                            <Avatar
                                                alt={comment.user.name}
                                                style={{
                                                    height: "25px",
                                                    width: "25px",
                                                    margin: "-5px -10px 0px 0px"
                                                }}><PersonOutlineOutlinedIcon/></Avatar>) :
                                        <Avatar
                                            alt="Anonymous"
                                            style={{
                                                height: "25px",
                                                width: "25px",
                                                margin: "-5px -10px 0px 0px"
                                            }}
                                        ><PersonOutlineOutlinedIcon
                                        /></Avatar>
                                )
                            }
                        </Grid>
                        <Grid item>
                            <Typography gutterBottom variant="body2" component="p"
                                        color="textPrimary" style={{
                                fontSize: '0.8rem',
                                paddingLeft: '5px',
                            }}>
                                <Link href={"/users?userId=" + comment.user.id}
                                      style={{
                                          color: '#3f51b5',
                                          textShadow: 'none',
                                          backgroundImage: 'none',
                                      }}>{comment.user.name}</Link>
                            </Typography>
                        </Grid>
                        <Grid item><Typography gutterBottom variant="body2" component="p"
                                               color="textPrimary" style={{
                            fontSize: '0.7rem',
                            padding: '1px 0px 0px 0px',
                        }}>{comment.date}</Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={() => Promise.resolve(showReviewAddComment(comment.id))}
                                style={{
                                    textShadow: 'none',
                                    backgroundImage: 'none',
                                    fontSize: '0.7rem',
                                    fontWeight: 400,
                                    textTransform: 'none',
                                    margin: "-6px 0px 0px 0px"
                                }}
                                startIcon={<ReplyIcon/>}
                            >
                                Ответить
                            </Button>
                            {comment.edit && <Button
                                onClick={() => Promise.resolve(showReviewEditComment(comment.id))}
                                style={{
                                    textShadow: 'none',
                                    backgroundImage: 'none',
                                    fontSize: '0.7rem',
                                    fontWeight: 400,
                                    textTransform: 'none',
                                    margin: "-6px 0px 0px 0px"
                                }}
                                startIcon={
                                    commentShowEdit.show !== comment.id ? <EditIcon/> : <CloseIcon/>
                                }
                            >
                                {commentShowEdit.show !== comment.id ? <>Править</> : <>Отменить</>}
                            </Button>}
                            {comment.delete && <Button
                                onClick={() => Promise.resolve(formDeleteComment(comment.id))}
                                style={{
                                    textShadow: 'none',
                                    backgroundImage: 'none',
                                    fontSize: '0.7rem',
                                    fontWeight: 400,
                                    textTransform: 'none',
                                    margin: "-6px 0px 0px 0px"
                                }}
                                startIcon={<DeleteIcon/>}
                            >
                                Удалить
                            </Button>}
                        </Grid>
                        {commentShowAdd.show === comment.id && <Grid item xs={12}>
                            <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Paper style={{
                                    padding: '2px 4px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: "100%",
                                    textAlign: "center",
                                    margin: "0px 0px 25px 0px"
                                }}>
                                    <IconButton onClick={closeReviewComment}>
                                        <CloseIcon/>
                                    </IconButton>
                                    <InputBase
                                        style={{
                                            marginLeft: "10px",
                                            flex: 1,
                                        }}
                                        multiline
                                        minRows={1}
                                        maxRows={8}
                                        placeholder="Ваш комментарий"
                                        onChange={event => handleCommentChange(event, comment.id)}
                                    />
                                    <Divider style={{
                                        height: 28,
                                        margin: 4,
                                    }} orientation="vertical"/>
                                    <IconButton
                                        onClick={() => Promise.resolve(addReviewComment(0, comment.id))}
                                        color="primary"
                                        style={{
                                            padding: 10,
                                        }} aria-label="directions">
                                        <SendIcon/>
                                    </IconButton>
                                </Paper>
                            </Grid>
                        </Grid>}
                    </Grid>
                </Grid>
            </Grid>

            {nestedComments}
        </div>
    )
}

let goToStartComment = false;
if (typeof window !== 'undefined') {
    if (window.location.hash !== undefined && window.location.href.includes('comments')) {
        goToStartComment = true;
    }
}

const CommentsSort = (props) => {

    const [comments, setComments] = useState([]);
    const commentTree = createTree(comments)
    const [dataLoading, setDataLoading] = useState(true)
    const [commentTotal, setCommentTotal] = useState(0)
    const [commentNew, setCommentNew] = useState([])
    const [commentShowAdd, setCommentShowAdd] = useState({show: null})
    const [commentShowEdit, setCommentShowEdit] = useState({show: null})
    const startComment = useRef(null)

    function getReviewComments() {
        let values;
        let formData = new FormData();
        let jwt = "";
        let email = "";
        if (localStorage.getItem("gatsbyUser") !== null) {
            if (JSON.parse(localStorage.getItem("gatsbyUser")).jwt !== null) {
                jwt = JSON.parse(localStorage.getItem("gatsbyUser")).jwt;
            }
            if (JSON.parse(localStorage.getItem("gatsbyUser")).email !== null) {
                email = JSON.parse(localStorage.getItem("gatsbyUser")).email;
            }
        }
        formData.append(
            "jwt", jwt,
        );
        formData.append(
            "email", email,
        );
        formData.append(
            "reviewId", props.reviewId,
        );
        values = formData;
        axios({
            method: "POST",
            data: values,
            url: "https://api.ocenivay.com/api/comments-review-get.php",
            responseType: 'json',
        })
            .then(res => {
                setDataLoading(false);
                setComments(res.data);
                setCommentTotal(parseInt(res.data.length));
                if (goToStartComment) {
                    startComment.current.scrollIntoView()
                }
            })
            .catch(function (error) {
                console.log('Error', error.message);
            });
    }

    function showReviewAddComment(parentId) {
        setCommentShowAdd({show: parentId})
    }


    const [openFormDeleteComment, setOpenFormDeleteComment] = useState(false);
    const [commentId, setCommentId] = useState(false);
    const [confirmCode, setConfirmCode] = useState(false);


    function formDeleteComment(commentId) {
        setCommentId(commentId);
        if (openFormDeleteComment) {
            setOpenFormDeleteComment(false);
        } else {
            setOpenFormDeleteComment(true);
        }
    }

    function handleCodeChange(e) {
        const target = e !== undefined ? e.target : undefined;
        if (target !== undefined) {
            const value = target.type === 'checkbox' ? target.checked : target.value;
            setConfirmCode(value);
        }
    }

    function deleteComment() {

        let formData = new FormData();
        let jwt = "";
        let email = "";
        if (localStorage.getItem("gatsbyUser") !== null) {
            if (JSON.parse(localStorage.getItem("gatsbyUser")).jwt !== null) {
                jwt = JSON.parse(localStorage.getItem("gatsbyUser")).jwt;
            }
            if (JSON.parse(localStorage.getItem("gatsbyUser")).email !== null) {
                email = JSON.parse(localStorage.getItem("gatsbyUser")).email;
            }
        }
        formData.append(
            "jwt", jwt,
        );
        formData.append(
            "email", email,
        );
        formData.append(
            "commentId", commentId,
        );
        formData.append(
            "confirmCode", confirmCode,
        );
        formData.append(
            "reviewId", props.reviewId,
        );

        axios({
            method: "POST",
            data: formData,
            url: "https://api.ocenivay.com/api/comment-review-delete.php",
            responseType: 'json',
        })
            .then(res => {
                setSnackbarMessage(res.data.severity, res.data.message)
                if (res.data.type === undefined) {
                    setOpenFormDeleteComment(false);
                }
                getReviewComments();
            })
            .catch(function (error) {
                console.log('Error', error.message);
            });
    }


    function showReviewEditComment(parentId) {
        if (commentShowEdit.show !== parentId) {
            setCommentShowEdit({show: parentId})
        } else {
            setCommentShowEdit({show: null})
        }
    }

    function closeReviewComment() {
        setCommentShowAdd({show: null})
        setCommentShowEdit({show: null})
    }

    function addReviewComment(id, parentId) {
        if (commentNew[parentId] === undefined || commentNew[parentId] === "") {
            setCommentNew({[parentId]: ""});
            setCommentShowAdd({show: null})
            setCommentShowEdit({show: null})
            return;
        }
        setDataLoading(true);

        let formData = new FormData();
        let jwt = "";
        let email = "";
        if (localStorage.getItem("gatsbyUser") !== null) {
            if (JSON.parse(localStorage.getItem("gatsbyUser")).jwt !== null) {
                jwt = JSON.parse(localStorage.getItem("gatsbyUser")).jwt;
            }
            if (JSON.parse(localStorage.getItem("gatsbyUser")).email !== null) {
                email = JSON.parse(localStorage.getItem("gatsbyUser")).email;
            }
        }
        formData.append(
            "jwt", jwt,
        );
        formData.append(
            "email", email,
        );
        formData.append(
            "reviewId", props.reviewId,
        );
        formData.append(
            "commentId", id,
        );
        formData.append(
            "commentParentId", parentId,
        );
        formData.append(
            "commentNew", commentNew[parentId] !== undefined ? commentNew[parentId] : "",
        );

        axios({
            method: "POST",
            data: formData,
            url: "https://api.ocenivay.com/api/comment-review-add.php",
            responseType: 'json',
        })
            .then(res => {
                setSnackbarMessage(res.data.severity, res.data.message)
                if (res.data.type === undefined) {
                    setCommentNew({[parentId]: ""});
                    setCommentShowAdd({show: null})
                    setCommentShowEdit({show: null})
                }
                getReviewComments();
            })
            .catch(function (error) {
                console.log('Error', error.message);
            });
    }

    function handleCommentChange(e, id) {
        const target = e !== undefined ? e.target : undefined;
        if (target !== undefined) {
            const value = target.type === 'checkbox' ? target.checked : target.value;
            setCommentNew({
                ...commentNew, [id]: value
            });
        }
    }

    useEffect(() => {
        getReviewComments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [severity, setSeverity] = useState("success");
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const handleClick = () => {
        setOpen(true);
    };
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    function setSnackbarMessage(severity, message) {
        severity = severity ? severity : "warning";
        message = message ? message : "Что-то пошло не так... Попробуйте позже...";
        setSeverity(severity)
        setMessage(message)
        handleClick();
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={open} autoHideDuration={4000} onClose={handleClose}
            >
                <Alert variant="filled" onClose={handleClose} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>

            <Dialog open={openFormDeleteComment} onClose={formDeleteComment} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Удаление комментария</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Чтобы удалить комментарий введите код "<b>{commentId}</b>" и нажмите удалить.
                    </DialogContentText>
                    <TextField
                        margin="dense"
                        id="name"
                        label="Код"
                        type="number"
                        fullWidth
                        onChange={event => handleCodeChange(event)}
                    />
                </DialogContent>
                <DialogActions
                    style={{
                        margin: "20px 16px 10px 16px",
                    }}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="baseline"
                    >
                        <Grid>
                            <Button onClick={formDeleteComment}
                                    variant="contained" size="small"
                                    style={{
                                        textShadow: 'none',
                                        backgroundImage: 'none',
                                        fontSize: '0.8rem',
                                        fontWeight: 400,
                                        textTransform: 'none',
                                        margin: "0 20px 0 0",
                                    }}
                            >
                                Отменить
                            </Button>
                        </Grid>
                        <Grid>
                            <Button onClick={deleteComment}
                                    variant="contained" size="small" color="primary"
                                    style={{
                                        textShadow: 'none',
                                        backgroundImage: 'none',
                                        fontSize: '0.8rem',
                                        fontWeight: 400,
                                        textTransform: 'none',
                                    }}
                            >
                                Удалить
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>

            <StyledPaper elevation={3}
                         style={{
                             margin: '20px 0px 20px 0px',
                             paddingBottom: "30px"
                         }}
            >
                <Typography variant="body1" component="p" color="textPrimary"
                            ref={startComment}
                            style={{
                                margin: "10px 0px 20px 0px",
                                textAlign: "center",
                                color: "#666"
                            }}
                ><CommentIcon
                    style={{
                        margin: "0px 7px -7px 0px",
                        color: "#666"
                    }}/>Комментарии ({commentTotal})</Typography>

                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Paper style={{
                        padding: '2px 4px',
                        display: 'flex',
                        alignItems: 'center',
                        width: "80%",
                        textAlign: "center",
                        margin: "0px 0px 25px 0px"
                    }}>
                        <InputBase
                            style={{
                                marginLeft: "10px",
                                flex: 1,
                            }}
                            multiline
                            minRows={1}
                            maxRows={8}
                            value={commentNew[0]}
                            placeholder="Ваш комментарий"
                            onChange={event => handleCommentChange(event, 0)}
                        />
                        <Divider style={{
                            height: 28,
                            margin: 4,
                        }} orientation="vertical"/>
                        <IconButton
                            onClick={() => Promise.resolve(addReviewComment(0, 0))}
                            color="primary"
                            style={{
                                padding: 10,
                            }} aria-label="directions">
                            <SendIcon/>
                        </IconButton>
                    </Paper>
                </Grid>


                {dataLoading && <Grid container spacing={3}>
                    <Grid item xs={12}
                          style={{
                              textAlign: 'center'
                          }}>
                        <>
                            <Typography variant="body2" gutterBottom><b>Загрузка...</b></Typography>
                            <CircularProgress/>
                        </>
                    </Grid>
                </Grid>}

                {commentTree.length > 0 &&
                <>
                    {commentTree.map((comment) => {
                        return <Comment
                            key={comment.id} comment={comment}
                            addReviewComment={addReviewComment}
                            showReviewAddComment={showReviewAddComment}
                            showReviewEditComment={showReviewEditComment}
                            handleCommentChange={handleCommentChange}
                            commentShowAdd={commentShowAdd}
                            commentShowEdit={commentShowEdit}
                            formDeleteComment={formDeleteComment}
                            deleteComment={deleteComment}
                            closeReviewComment={closeReviewComment}
                        />
                    })}
                </>
                }
            </StyledPaper>
        </>
    )
}

CommentsSort.propTypes = {
    window: PropTypes.func,
};

export default CommentsSort
